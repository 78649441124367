/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../data'
import Subscribe from '.././Component/Subscribe'
import { get, post } from '../http'
import { Skeleton, message, Spin } from 'antd'
import './less/antMotionStyle.less'
import { useParams, Link, useNavigate } from 'react-router-dom'
message.config({
  top: 100
})

let isMobile
enquireScreen((b) => {
  isMobile = b
})

const { location = {} } = typeof window !== 'undefined' ? window : {}
function App(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [right, setRight] = useState(0)
  const myRef = useRef(null);
  const [email, setEmail] = useState(null)
  const [show, setShow] = useState(false)
  const [isCheck, setIsCheck] = useState(0)
  const [isSumbit, setSumbit] = useState(false)
  function isValidEmail(email) {
    // 定义邮箱的正则表达式
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // 使用test方法检测邮箱格式是否符合正则表达式
    return emailRegex.test(email)
  }

  useEffect(() => {
    window.addEventListener('scroll', () => {
      var scrollTop = window.scrollY;
      setShow(scrollTop >= 800)
      
    });
  }, [])

  async function subscribeEmail() {
    try {
      if (email === '') {
        return
      }
      const check = isValidEmail(email);
      setIsCheck(check ? 1 : -1);
      if (!check) {
        return
      }
      let url1 = `https://sx.shixiangcap.com/sxfiles/etna/login/tmp/user/insert`
      setLoading(true)
      const response = await post(url1, {
        email: email
      })
      setLoading(false)

      if (response.code !== 200) throw new Error(response.msg)
      // message.success('Subscribe Success !')
      setIsCheck(0)
      console.log(response.data)
      setSumbit(true)
      return response
    } catch (e) {
      setLoading(false)
      setSumbit(false)

      return {
        success: false,
        message: e.message
      }
    }
  }
  const [data, setData] = useState({
    title: '',
    html: '',
    time: '',
    menu: []
  })

  const [currentIndex, setCurrentIndex] = useState(0)
  const [visible, setVisble] = useState(false)
  const [left, setLeft] = useState(isMobile ? 0 : 32)
  const goBackHandler = () => {
    navigate(-1) // This is equivalent to history.goBack()
  }
  async function getDetail() {
    try {
      let url1 = `https://sx.shixiangcap.com/sxfiles/etna/article/findByUid?uid=${id}`
      setVisble(true)
      const response = await get(url1, {})
      setVisble(false)

      if (response.code !== 200) throw new Error(response.msg)
      console.log(response.data)

      const data = checkContent(response.data.content)
      const op = {
        banner: response.data.banner,
        title: response.data.title,
        time: response.data.customTime,
        html: data.content,
        menu: data.menu
      }
      setData(op)
      setTimeout(() => {
        setRight(myRef.current.offsetLeft + 30)
      }, 50)

      return response
    } catch (e) {
      setVisble(false)

      return {
        success: false,
        message: e.message
      }
    }
  }

  const checkContent = (content) => {
    const reg3 = /<(h1)[^>]*>(.*?)[^<]*<\/(\1)>/gi
    // const reg3 = /<(h1|h2|h3|h4|h5|h6)[^>]*>(.*?)[^<]*<\/(\1)>/gi;
    const reg = /<[^<>]+>/g
    let str1 = content.match(reg3) || []

    console.log(str1);
    let contentstr = content
    str1 = str1
      .filter((i) => {
        const textContent = i.replace(/<[^>]*>/g, '') // 使用正则表达式去除HTML标签
        return textContent.trim() !== '' // 检查文本内容是否为空
      })
      .map((i, h) => {
        const index = i.substring(0, 3)
        const restr = i.replace(index, `${index} id="jump_${h}" style="font-size:24px"`)
        contentstr = contentstr.replace(i, restr)
        const si = i.replace(reg, '')
        return si
      })
    return { menu: str1, content: contentstr }
  }

  useEffect(() => {
    getDetail()

    const element = document.getElementById('left')
    if (element) {
      const rect = element.getBoundingClientRect()
      setLeft(rect.left)
    }
  }, [])

  useEffect(() => {
    
  }, [props])
  console.log(id)
  return (
    <>
      <div className="detail-content">
        {visible && (
          <div style={{ maxWidth: '1200px', margin: 'auto' }}>
            <Skeleton paragraph={{ rows: 20 }} active />
          </div>
        )}
        {!visible && (
          <>
            <div className="detail-title">
              <div className="detail-title-text" style={{ position: 'relative', zIndex: 999, textShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)' }}>
                <div style={isMobile ? {} : { width: '672px', marginLeft: '258px' }}>
                  {data.title}
                </div>
              </div>
              <img className="detail-mask-img" src={`${data.banner}`} />
              <div className="detail-mask"></div>
            </div>
            <div className="detail-time">
              {/* <div className="detail-time-div">
              <img src={`/images/shijian.png`} />
            </div> */}
              <div className="detail-time-text">
                <div style={isMobile ? {} : { width: '672px', marginLeft: '258px' }}>
                  {data.time}
                </div>
              </div>
            </div>

            <div className="detail-row">
              {!isMobile && (
                <div style={{ width: '360px', display: 'flex', justifyContent: 'flex-end' }}>
                  <div id="left" className="detail-menu">
                    <div className="detail-point-title">CONTENTS</div>
                    <div>
                      {/* <div className="detail-point">
            <div className="detail-point-icon"></div>
            <div className="detail-point-line"></div>
          </div> */}
                      <div className="detail-point-list">
                        {data.menu.map((i, j) => {
                          return (
                            <div
                              onClick={() => {
                                const anchor = document.getElementById(`jump_${j}`)
                                const offset = 100 // 设置偏移量，以像素为单位
                                const anchorPosition =
                                  anchor.getBoundingClientRect().top +
                                  window.scrollY
                                window.scroll({
                                  top: anchorPosition - offset,
                                  behavior: 'smooth' // 可选的平滑滚动
                                })
                                setCurrentIndex(j)
                              }}
                              key={j}
                              className={`detail-point-item ${currentIndex === j ? 'active' : ''
                                }`}
                                
                            >
                              <span dangerouslySetInnerHTML={{ __html: i }}></span>
                            </div>
                          )
                        })}
                      </div>
                      <div>
                        <Link to="/research">
                          <div
                            //  onClick={() =>goBackHandler()}

                            className="back-btn"
                          >
                            <img style={{ width: '14px', height: '14px', marginRight: '8px' }} src={`/home/Frame.png`} />
                            Back to all
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {!isMobile &&<div className="detail-menu">
        <div className="detail-point-title">
        TABLE OF CONTENTS
        </div>
        <div className="detail-row">
          <div className="detail-point">
            <div className="detail-point-icon"></div>
            <div className="detail-point-line"></div>
          </div>
          <div className="detail-point-list">
            {data.menu.map((i,j)=>{
              return  <div key={j} className="detail-point-item">{i}</div>
            })}
          </div>
        </div>
      </div>} */}
              <div className="detail-body">
                {/* <div className="detail-img">
                <img src={`${data.banner}`} />
              </div> */}
                <div className="ql-snow  detail-body-text">
                  <div className='ql-editor' dangerouslySetInnerHTML={{ __html: data.html }} />
                  <div className="detail-tip">
                    Some images in this article are sourced from the internet and
                    belong to their respective owners. If you believe any of the
                    images infringe upon your rights or are not properly
                    attributed, please contact us directly, and we will promptly
                    address the issue.
                  </div>
                  <div className="detail-tip">
                    Please note that the information provided in this article is
                    for informational purposes only and should not be construed as
                    investment advice or a recommendation to buy, sell or
                    otherwise transact in any investment including any products or
                    services or an invitation to engage in any investment
                    activity. The content presented here is based on the author's
                    research, analysis and interviews, and individual readers are
                    encouraged to conduct their own due diligence before making
                    any investment decisions.
                  </div>
                  <div className="detail-tip">
                    The content of this article, including text, graphics, images,
                    and any other material, is protected by copyright laws. All
                    rights are reserved, and unauthorized use, reproduction, or
                    distribution of the content for commercial purposes is
                    strictly prohibited without our prior written permission.
                  </div>
                </div>
              </div>

              {!isMobile && (
                <div ref={myRef} className="detail-point-block-div">
                  <div className="detail-point-block-title">
                    Stay on the cutting edge of AI !
                  </div>
                  {!isSumbit && <>
                    <input
                      onChange={(e) => {
                        setEmail(e.target.value)
                        if(e.target.value===''){
                          setIsCheck(0)
                        }
                      }}
                      onFocus={() => {
                        setIsFocused(true);
                      }} onBlur={() => {
                        setIsFocused(false);
                      }}
                      style={{border:isCheck === -1?'1px solid #C45B48':''}}
                      placeholder={isFocused ? '' : "Enter your email address *"}
                      className="detail-point-block-input"
                    />
                    
                  <div>
                    {email !== '' && isCheck === -1 && <span style={{ color: '#C45B48' }} className="failed">*Invalid email address</span>}

                  </div>
                    <div
                      onClick={subscribeEmail}
                      className="detail-point-block-btn"
                    >
                      {' '}
                      SUBSCRIBE&nbsp;
                      <Spin spinning={loading}></Spin>
                    </div>
                  </>}

                  {isSumbit && <>
                    <div className="detail-point-block-sumbit">
                      Subscription successful <img src={'/home/circle4.png'} ></img>
                    </div>

                  </>}



                  <div className="detail-point-block-tip">
                    By clicking the Subscribe button, you agree to the <span className='detail-point-block-tip1'>Privacy Policy.</span>
                  </div>
                </div>
              )}
            </div>


          </>
        )}
      </div>
      {show && <div onClick={() => {
        window.scroll({
          top: 0,
          behavior: 'smooth' // 可选的平滑滚动
        })
      }} style={{ left: `${right}px` }} className="detail-top">
        <img src="/home/top.png"></img>
        Top
      </div>}
      <Subscribe />
    </>

  )
}

export default App
