import React,{useEffect, useState} from 'react'

import TweenOne from 'rc-tween-one';
import { Row, Col } from 'antd';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import { get,post } from '../http'

import { useNavigate,Link,useLocation  } from 'react-router-dom';


function App (props){
  const { dataSource, isMobile } = props;
  const queue = isMobile ? 'bottom' : 'left';
  const imgAnim = isMobile
    ? {
      y: 30,
      opacity: 0,
      delay: 600,
      type: 'from',
      ease: 'easeOutQuad',
    }
    : {
      x: 30,
      opacity: 0,
      type: 'from',
      ease: 'easeOutQuad',
    };





    const [list,setList]= useState([])

  async function getList() {
    try {
      let url1 = `https://sx.shixiangcap.com/sxfiles/etna/article/searchCount`;
      const response = await post(url1, {
        "counted": true,
          "orders": "order by customOrder asc",
          "pageIndex": 0,
          "pageSize": 3,
          "wheres": [{
            "key": "publishedStatus",
            "operator": "=",
            "value": 1
          }]
      })
      
      if (response.code !== 200) throw new Error(response.msg)
      
       
      setList(response.data.records)

      return response
    } catch (e) {
      return {
        success: false,
        message: e.message
      }
    }
  }

  useEffect(()=>{
    getList()
  },[])


    return (<div {...props} {...dataSource.wrapper} style={{overflow: 'hidden'}}>
      <div className="research-wrapper-img"></div>
      <OverPack {...dataSource.OverPack} component={Row}>
        <QueueAnim
          key="text"
          type={queue}
          leaveReverse
          ease={['easeOutQuad', 'easeInQuad']}
          {...dataSource.textWrapper}
          component={Col}
        >
          <div style={{marginRight:'34px'}} key="title">
             <div className="research-title">ETNA Research</div>
             {list.map((i,j)=>{
              return <Link key={`${j}`} to={`/details/${i.uid}`} target="_blank" >
              <div className="research-item" key={j}>
                <div className="research-item-icon"></div>
                <div className="research-item-title">
                  <div className="research-item-title-it">{i.title}</div>
                  <div className="research-item-line"></div>
                  </div>
             
              </div>
              </Link>
             })}

             <Link to={`research`}>
             <div className="research-item-btn">
                {`>> learn more`}
             </div>
             </Link>
          </div>
        
        </QueueAnim>
        <TweenOne
          key="img"
          animation={imgAnim}
          resetStyle
          {...dataSource.img}
          component={Col}
        >
          <div className="research-img">
          </div>

        </TweenOne>
      </OverPack>
    </div>)
}

export default App;
