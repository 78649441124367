/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React, { useEffect, useRef, useState } from 'react'

import { enquireScreen } from 'enquire-js'
import { DataSource } from '../../data'
import Subscribe from '../../Component/Subscribe'
import { get, post } from '../../http'
import { Skeleton, message, Spin } from 'antd'
import { useParams, Link, useNavigate } from 'react-router-dom'
import FilterImg from '../FilterImg'
import { DownloadIcon, RightAwoIcon } from '../icon'
import TableComponent from '../Table'
import ModalComponent from "../Modal"
import './index.less'
message.config({
    top: 100
})

let isMobile
enquireScreen((b) => {
    isMobile = b
})

function App(props) {

    const [researchlist, setResearchList] = useState([])
    const [active, setActive] = useState(false)

    async function getList() {
        try {
            let url1 = `https://sx.shixiangcap.com/sxfiles/etna/article/searchCount`;
            const response = await post(url1, {
                "counted": true,
                "orders": "order by createTime desc",
                "pageIndex": 0,
                "pageSize": 1,
                "wheres": [{
                    "key": "publishedStatus",
                    "operator": "=",
                    "value": 1
                }, {
                    "key": "isFrontPage",
                    "operator": "=",
                    "value": 1
                }]
            })
            if (response.code !== 200) throw new Error(response.msg)
            setResearchList(response.data.records)

            return response
        } catch (e) {
            return {
                success: false,
                message: e.message
            }
        }
    }

    useEffect(() => {
        getList()
    }, [])



    useEffect(() => {

    }, [])

    useEffect(() => {
        
    }, [props])
    return (
        <div className="resources-content">
              <div className='companies-deep'>
                <div className='companies-deep_title'>AGI Deep Dive</div>
                {researchlist.map((item, index) => (<div
                    key={index}
                    onClick={() => {
                        window.open(`https://www.etnalabs.co/details/${item.uid}`, "_blank");
                    }}
                    className='companies-deep_block'>
                    <div className='companies-deep_block_img'>
                        <img className='companies-deep_block_imgtag' src={item.banner} alt={item.title} />
                    </div>
                    <div className='companies-deep_block_body'>
                        <div>
                            <div className='companies-deep_block_tag'>EDITOR'S CHOICE</div>
                        </div>
                        <div className='companies-deep_block_title'>
                            {item.title}
                        </div>
                    </div>
                </div>))}
                <div onClick={() => {
                    window.open('https://www.etnalabs.co/research', "_blank");
                }} className='companies-deep_btn'>
                    See All Articles &nbsp;
                    <RightAwoIcon />
                </div>

            </div>
            <div  className='resources-head'>
            <div className='resources-top'>
                <div className='resources-top-content'>
                <div className='resources-title'>AGIX Investment Solutions</div>
                <div className='resources-tip'>Product Linked to the Solactive Etna Generative Artificial Intelligence Index is available</div>
                <div className='resources-tip1' onClick={() =>setActive(true)}>Explore Now</div>
                </div>

            </div>
            {/* <div  className='resources-bg'></div> */}
            </div>

            <div className='resources-body'>
                <div className='resources-body-tip'>Disclamer</div>
                <div className='resources-body-content'>The information provided in the Solactive Etna Generative Artificial Intelligence Index（AGIX Index）  is purely for informational and discussion purposes and should not be interpreted as, nor intended to be a recommendation for any transaction, investment, or any other type of financial, tax, or investment advice by Etna Research and Etna Capital Management（ Etna team）. Etna Team makes no warranties or representations concerning the accuracy, completeness, or timeliness of the information or the data presented in this index. Etna Research, and/or any investment vehicles it manages, along with individuals and entities associated with these vehicles, may buy, sell, or hold securities of companies that are constituents of the AGIX Index at any given time, including before or after such companies are added to or removed from the index.</div>
                <div className='resources-body-desc'>For complete index information including methodology, visit <span className='resources-body-link'>SOLACTIVE</span>.</div>
            </div>


            <ModalComponent active={active} onClose={()=>setActive(false)}/>

        </div>

    )
}

export default App
