import React, { useEffect, useRef, useState } from 'react'

import { Button, message, Spin } from 'antd'
import { get, post } from '../http'
import './index.less'
message.config({
  top: 100
})

function App({isWhite=true}) {
  const [visible, setVisble] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isCheck, setIsCheck] = useState(0)
  const [isSumbit, setIsSumbit] = useState(false)
  const [email, setEmail] = useState('')
  function isValidEmail(email) {
    // 定义邮箱的正则表达式
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // 使用test方法检测邮箱格式是否符合正则表达式
    return emailRegex.test(email)
  }
  async function subscribeEmail() {
    try {
      if(email===''){
        return
      }
      const check = isValidEmail(email);
      setIsCheck(check?1:-1);
      if (!check) {
        return
      }
      let url1 = `https://sx.shixiangcap.com/sxfiles/etna/login/tmp/user/insert`
      setVisble(true)
      const response = await post(url1, {
        email: email
      })
      setVisble(false)

      if (response.code !== 200) throw new Error(response.msg)
      // message.success('Subscribe Success !')
      setIsSumbit(true)
      setIsCheck(0)
      console.log(response.data)
      return response
    } catch (e) {
      setVisble(false)
      setIsSumbit(false)
      return {
        success: false,
        message: e.message
      }
    }
  }

  return (
    <div style={{background:isWhite?'#fff':'#C45B48'}} className="sub-block">
          {!isSumbit&&<div style={{color:isWhite?'#000':'#fff'}} className="sub-tip">
        Stay updated on Etna Research<br/> for cutting-edge insights
      </div>}
      {isSumbit&&<div  className="sub-tip" style={{marginBottom:'16px',color:isWhite?'#000':'#fff'}}>
       <img src={isWhite?'/home/circle3.png':'/home/circle2.png'} alt="" style={{width: '52px',height:'52px',marginBottom:'11px'}}/>
       <div>
      Subscription successful
       </div>
      </div>}
      <div className="sub-row">
        <div style={{position: 'relative'}}>
        <input
          onChange={(e) => {
            setEmail(e.target.value)
           if(e.target.value===''){
            setIsCheck(0);
           }
          }}
          onFocus={()=>{
            setIsFocused(true);
         }} 
         onBlur={(e)=>{
          setIsFocused(false);
         }} 
         style={{border:isWhite?'1px solid #989AA5':'0'}}
          placeholder={isFocused?'':"Enter your email address"}
          className={`sub-input ${email!==''&&isCheck===-1?'failed-line':''} ${email!==''&&isCheck?'success-line':''}`}
        />
        <div style={{position: 'absolute'}}>
        {email!==''&&isCheck===-1&&<span style={{color:isWhite?'#C45B48':'#fff'}} className="failed">*Invalid email address</span>}
        {/* {email!==''&&isCheck===1&&<span style={{color: '#00DB7F'}} className="failed">*Verification passed</span>} */}
        </div>
      
        </div>
        <div onClick={subscribeEmail} className="sub-btn" style={{background:isWhite?'#C45B48':'#DD9689'}}>
          Subscribe&nbsp;
          <Spin spinning={visible}></Spin>
        </div>
      </div>
  
    </div>
  )
}

export default App
